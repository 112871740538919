import { render, staticRenderFns } from "./member.vue?vue&type=template&id=a9fadb38&scoped=true&"
import script from "./member.vue?vue&type=script&lang=js&"
export * from "./member.vue?vue&type=script&lang=js&"
import style0 from "./member.vue?vue&type=style&index=0&id=a9fadb38&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9fadb38",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
