<template>
  <v-list-item
    :class="['cy-member', { 'cy-member--missing': isMissing }]">
    <template v-if="isMissing">
      <v-icon
        :key="username"
        data-cy="member-replacement-avatar"
        class="cy-member__avatar"
        :sm="sm"
        :lg="lg">
        error_outline
      </v-icon>
      <v-list-item-title
        data-cy="member-name"
        :class="['cy-member__title', {
          'font-weight-bold': active,
        }]">
        {{ title }}
      </v-list-item-title>
    </template>

    <template v-else>
      <CyAvatar
        :key="username"
        data-cy="member-avatar"
        :class="['cy-member__avatar', { 'cy-focus': avatarOnly }]"
        :item="member"
        :sm="sm"
        :lg="lg"/>
      <v-list-item-content
        v-if="!avatarOnly"
        class="cy-member__body">
        <v-list-item-title
          data-cy="member-name"
          :class="['cy-member__title', {
            'font-weight-bold': active,
          }]">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="!simple"
          class="cy-member__subtitle">
          <span
            data-cy="member-username"
            class="cy-member__username">{{ username }} • </span>
          <span
            data-cy="member-email"
            class="cy-member__email">{{ email }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
import { displayName, hasNoOwner } from '@/utils/helpers'

export default {
  name: 'CyMember',
  props: {
    member: {
      type: Object,
      validator: (member) => _.$hasAny(member, [
        'email',
        'family_name',
        'given_name',
        'picture_url',
        'username',
      ]),
      required: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    avatarOnly: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title () {
      return displayName(this.member)
    },
    username () {
      return this.member?.username
    },
    email () {
      return this.member?.email
    },
    isMissing () {
      return hasNoOwner(this.member)
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-member {
  font-weight: 400;
  text-align: left;

  &.v-list-item,
  .v-list-item__content {
    flex: 0 1 auto;
    padding: 0;
  }

  &__body {
    flex-direction: column;
    margin: 0 8px;
  }

  &__title,
  &__subtitle {
    align-self: flex-start;
  }

  &__title {
    height: 16px;
    color: get-color("primary");
    font-size: map.get($font-sizes, "base") !important;
    line-height: 16px;
    text-align: left;
  }

  &__username,
  &__email {
    padding-top: 2px;
    color: get-color("primary", "light-2") !important;
    font-size: 12px !important;
    line-height: 150%;
    text-align: left;
  }

  &:hover {
    background: none;
  }

  &--large {
    .cy-member__title {
      height: 19px;
      font-size: 16px;
    }
  }

  &--missing {
    height: fit-content;
    min-height: fit-content;
    padding: 0.5em 0.8em 0.5em 1.35em !important;
    border: 1px dashed get-color("grey");
    border-radius: 4px;
    font-style: italic;

    .cy-member {
      &__avatar.v-icon.v-icon:not(.cy-btn--icon) {
        color: get-color("grey") !important;
      }

      &__body {
        margin-left: 0;
      }

      &__title,
      &__subtitle {
        align-self: center;
      }

      &__title {
        margin-left: 6px;
      }
    }
  }
}

@media screen and (max-width: 1590px) {
  .cy-member {
    max-width: 210px;

    &__username {
      display: none;
    }
  }
}
</style>
